



































import { View } from '@/app_code/services/meta/view'
import { Mixins } from 'vue-property-decorator'

export default class NotFound extends Mixins(View) {

}
